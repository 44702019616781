/* .slick-slide { 
  float: none; 
} */
/* ::-webkit-scrollbar {
  width: 2em;
  height: 2em
}
::-webkit-scrollbar-button {
  background: #ba0606
}
::-webkit-scrollbar-track-piece {
  background: #940202
}
::-webkit-scrollbar-thumb {
  background: #a90202
}​ */

.font-bayon {  font-family: 'Bayon';}
.font-bungee-shade {  font-family: 'Bungee Shade';}
.font-eater {  font-family: 'Eater';}
.font-hunk  {  font-family: 'Honk';}
.font-jura          {  font-family: 'Jura';}
.font-monoton {  font-family: 'Monoton';}
.font-open-sans     {  font-family: 'Open Sans';}
.font-parisienne {  font-family: 'Parisienne';}
.font-roboto {  font-family: 'Roboto';}
.font-titanOne{  font-family: 'TitanOne';}
          
#color-popover,
.MuiPaper-root.MuiPopover-paper .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  z-index: 2051;
}

.w-55{ width: 55%;} .h-55{ height: 55%;}
.w-60{ width: 60%;} .h-60{ height: 60%;}
.w-65{ width: 65%;} .h-65{ height: 65%;}
.w-70{ width: 70%;} .h-70{ height: 70%;}
.w-80{ width: 80%;} .h-80{ height: 80%;}
.w-85{ width: 85%;} .h-85{ height: 85%;}
.w-90{ width: 90%;} .h-90{ height: 90%;}
.w-95{ width: 95%;} .h-95{ height: 95%;}

.app-toolbar{
  display: none;
}

.slick-slide > div {  
  margin: 0 10px;
}
.slick-slide:first-child > div{
  margin-left: 0px;
  margin-right: 20px;
}
.slick-slide:last-child > div{
  margin-left: 20px;
  margin-right: 0px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-prev:before,
.slick-next:before{
  color:#E4E6EF;  
}
.slick-prev {
  left: -15px;
}

.slick-dots li{
  margin-top: 8px;
  margin-right: 5px;
}


/* .slick-prev:before{
  content:'\F284';
}
.slick-next:before{
  content:'\25BA';
} */

.slick-dots{
  text-align: right;
}

/* .slider {
  width: 650px;
  margin: 0 auto;
} */
/* .slick-dots{
position: absolute;
bottom: 20px;
display: inline-block;
width: 100%;
padding: 10;
margin: 0;
list-style: none;
text-align: center;
}*/
.slick-dots li div{  
  color:white;
  /* background-color: #E9E1F6; */
  background-color: #F3CCEE;
} 
.slick-dots li.slick-active div{  
  color: #f4f4f4;
  /* background-color: #331168; */
  background-color: #9C258F;
} 
/* .slick-slide { float: none; } */


.border-light-primary-darker{
  /* border-color:#E9E1F688; */
  /* border-color:#CABBE488; */
  /* border-color:#33116822; */
  /* border-color:#B5B5C355; */
  
  /* border-color:#48256C; */
  border-color: #32002C;
}

.bordered-1{
  border: 1px solid #ccc;
}

.custom-border{
  border: 1px solid #c4c4c4;
}
.custom-border:hover{
  border: 1px solid #000;
}

.section-outline-hover-primary{
  /* outline: 1px dashed #331168; */
  outline: 1px dashed #9C258F;
}
.section-outline-hover-info{
  outline: 1px dashed #009ef7;
}
.section-outline-hover-success{
  outline: 1px dashed #4FA34B;
}
.section-outline-hover-warning{
  outline: 1px dashed #ffc700;
}
.section-outline-hover-danger{
  outline: 1px dashed #C80815;
}

[data-kt-app-sidebar-minimize=on] .storage-usage .storage-usage-info{
  font-size: 0.8rem !important;
}
[data-kt-app-sidebar-minimize=on] .storage-usage .storage-usage-info span:nth-child(1){  
  margin-left: -5px;
}
[data-kt-app-sidebar-minimize=on] .storage-usage .storage-usage-info span:nth-child(2){
  margin-right: -7px;
}
[data-kt-app-sidebar-minimize=on] .storage-usage .storage-usage-title,
[data-kt-app-sidebar-minimize=on] .storage-usage .storage-usage-of  {
  display: none !important;
}

[data-kt-app-sidebar-minimize=on] .menu-item-space {
  display: none !important;
}

.item-hover-primary{
  border: 1px solid #fff;
}
.item-hover-primary:hover{
  border: 1px solid #9C258F;
  background-color: #9C258F;
  border-radius: 0 0 2px 2px;
  /* background-color: #E9E1F688; */
  /* border: 1px solid #48256C inset; */
  /* box-shadow: inset 0 0 1px #48256C; */
  box-shadow: inset 0 0 1px #32002C;
}
.item-hover-light-primary{
  border: 1px solid #fff;
}
.item-hover-light-primary:hover{
  border: 1px solid #FBE5F9;
  background-color: #FBE5F9;
  border-radius: 0 0 2px 2px;
  /* background-color: #E9E1F688; */
  /* border: 1px solid #48256C inset; */
  /* box-shadow: inset 0 0 1px #48256C; */
  box-shadow: inset 0 0 1px #D58DCF;
}

.css-10062bc-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
  opacity: 0;
  color: #fff;
}

.bg-dark{     background-color:#3F4254; }
.bg-gray-20{  background-color:#fcfcfc; } .bg-hover-gray-20:hover{  background-color:#fcfcfc; }
.bg-gray-50{  background-color:#fafafa; } .bg-hover-gray-50:hover{  background-color:#fafafa; }
.bg-gray-100{ background-color:#f5f8fa; } .bg-hover-gray-100v{  background-color:#f5f8fa; }
.bg-gray-200{ background-color:#eff2f5; } .bg-hover-gray-200:hover{  background-color:#eff2f5; }
.bg-gray-300{ background-color:#E4E6EF; } .bg-hover-gray-300:hover{  background-color:#E4E6EF; }
.bg-gray-400{ background-color:#B5B5C3; } .bg-hover-gray-400:hover{  background-color:#B5B5C3; }
.bg-gray-500{ background-color:#A1A5B7; } .bg-hover-gray-500:hover{  background-color:#A1A5B7; }
.bg-gray-600{ background-color:#7E8299; } .bg-hover-gray-600:hover{  background-color:#7E8299; }
.bg-gray-700{ background-color:#5E6278; } .bg-hover-gray-700:hover{  background-color:#5E6278; }
.bg-gray-800{ background-color:#3F4254; } .bg-hover-gray-800:hover{  background-color:#3F4254; }
.bg-gray-900{ background-color:#181C32; } .bg-hover-gray-900:hover{  background-color:#181C32; }
.bg-gradient-primary{  background-image: linear-gradient(25deg, #9C258F, #F04E5D); }
.bg-gradient-primary-o75{  background-image: linear-gradient(25deg, #9C258Fcc, #F04E5Dcc); }
.bg-gradient-light-primary{  background-image: linear-gradient(25deg, #9C258F55, #F04E5D55); }
.bg-gradient-primary-90degree{  background-image: linear-gradient(90deg, #9C258F 0%, #9C258F 25%, #F04E5D 100%);}
.border-bottom-primary{
  border-bottom: 2px solid #9C258F;
}


.max-w-landscape{  max-width: 154px;  }
.max-w-portrait {  max-width: 154px;  }



button[aria-expanded='false'] > .expand_caret {
  transform: scale(1) rotate(-90deg);
}

.link-hover-with-icon .link-hover-icon{
  display: none;
}
.link-hover-with-icon:hover .link-hover-icon{
  display: inline-block;
}

/* #kt_app_header{
  opacity: 0.25;
} */


time.icon {
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width : 50px;
  height: 50px;
  background-color: #fff;
  margin: 0 15px 0 0;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon * {
  display: block;
  width: 100%;
  font-size: 0.7em;  
  /* font-weight: bold; */
  font-style: normal;
  text-align: center;
}

time.icon strong {
  position: absolute;
  top: 0;
  padding: 1px 0;
  color: #fff;
  background-color: #9C258F;
  border-bottom: 1px dashed #711366;
  box-shadow: 0 2px 0 #58054e;
  text-transform: uppercase;
}

time.icon em
{
  position: absolute;
  bottom: 0.3em;
  color: #fd9f1b;
}

time.icon span
{
  width: 100%;
  font-size: 2.1em;
  letter-spacing: -0.05em;
  padding-top: 15px;
  color: #2f2f2f;
}


/* For Marquee text */
/* .rfm-marquee-container[style*="rotate(-90deg)"]{ */
  /* writing-mode: vertical-rl; */
  /* writing-mode: vertical-lr; */
  /* writing-mode: horizontal-tb; */
  /* transform: none; */
  /* transform: rotate(-90deg);
  overflow: hidden; */
  /* height: 100vh; */
  /* transform: rotate(180deg); */
  /* white-space: wrap; */
  /* display: inline-block; */
  /* -webkit-transform:rotate(-90deg) translate(-3rem,-3rem);
  -moz-transform: rotate(-90deg) translate(-3rem,-3rem);
  -ms-transform: rotate(-90deg) translate(-3rem,-3rem);
  -o-transform: rotate(-90deg) translate(-3rem,-3rem); */
  /* transform: rotate(-90deg) ; */
  /* width: 27rem;
  height: 33rem; */
/* } */
.rfm-marquee-container[style*="rotate(-90deg)"] .rfm-marquee, 
.rfm-marquee-container[style*="rotate(-90deg)"] .rfm-initial-child-container,
.rfm-marquee-container[style*="rotate(90deg)"] .rfm-marquee, 
.rfm-marquee-container[style*="rotate(90deg)"] .rfm-initial-child-container
{
  overflow: hidden;
  width: 100% !important;  
}

/* time.icon:hover, time.icon:focus
{
  -webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;
} */

/* @-webkit-keyframes swing {
  0%   { -webkit-transform: rotate(0deg)  skewY(0deg); }
  20%  { -webkit-transform: rotate(12deg) skewY(4deg); }
  60%  { -webkit-transform: rotate(-9deg) skewY(-3deg); }
  80%  { -webkit-transform: rotate(6deg)  skewY(-2deg); }
  100% { -webkit-transform: rotate(0deg)  skewY(0deg); }
}

@keyframes swing {
  0%   { transform: rotate(0deg)  skewY(0deg); }
  20%  { transform: rotate(12deg) skewY(4deg); }
  60%  { transform: rotate(-9deg) skewY(-3deg); }
  80%  { transform: rotate(6deg)  skewY(-2deg); }
  100% { transform: rotate(0deg)  skewY(0deg); }
} */

/*------For z-Index ------- */
/* .z-index-custom-top1 {  z-index: 99 !important  } */
/* .z-index-custom-top2 {  z-index: 2002;  }
.z-index-custom-top3 {  z-index: 2003;  }
.z-index-custom-top4 {  z-index: 2004;  } */


.hover-text-display .hover-icon-display{
  display: none;
}
.hover-text-display:hover .hover-icon-display{
  display: inline;
}
.hover-text-display:hover .hover-icon-hidden{
  display: none;
}




/*------For Schedule Gantt Chart ------- */
/* ------------------------------------- */
.task-list-table{
  width: 300px;
}
/* basic positioning */
.legend { list-style: none; }
.legend li { 
    display:d-block; 
    /* float:left;  */
    margin-right: 10px; 
}
.legend span { border: 1px solid #ccc; float: right; width: 12px; height: 12px; margin: 2px; }
/* your colors */
.legend .select-schedule    { background-color: #ffc107; }
.legend .active-schedule    { background-color: #198754; }
.legend .past-schedule      { background-color: #B5B5C3; }
.legend .standard-schedule  { background-color: #009ef7; }
.legend .recurring-schedule { background-color: #9C258F; }
.legend .upcoming-standard-schedule  { background-color: #009ef735; }
.legend .upcoming-recurring-schedule  { background-color: #9C258F35; }


/* .responsive-preview-container{
  width: 20% !important;
  height: 20% !important;
} */

/*------For Shopping Cart ------- */
/* ------------------------------------- */
.shopping-cart-popup{
  max-height: 500px;
}

/* #app-wrapper, #kt_app_wrapper{
  height: 70px;
} */

@media only screen and (min-width: 1201px) {
  /* #app-wrapper{height: 70px;} */

  .responsive-preview-container{
    width:  50% !important;
    height: 50% !important;
  }

  .shopping-cart-popup{
    max-height: 750px;
  }

  /*------For Campaign/Playlist page ------- */
  .width-content {  width: 65%  }
  .width-playlist{  width: 75%  }

  .height-landscape{  height: 150px}
  .height-portrait {  height: 225px}
}

@media only screen and (max-width: 1200px) {
  .responsive-preview-container{
    width:  50% !important;
    height: 50% !important;
  }

  .shopping-cart-popup{
    max-height: 475px;
  }

  /*------For Campaign/Playlist page ------- */
  .width-content {  width: 65%  }
  .width-playlist{  width: 75%  }

  .height-landscape{  height: 150px}
  .height-portrait {  height: 200px}
}
@media only screen and (max-width: 1024px) {
  .task-list-table{
    width: 275px;
  }
}

/* @media only screen and (min-width: 992px) {
  #app-wrapper{height: 60px;}
} */
@media only screen and (max-width: 992px) {  
  /* #kt_app_main{ margin-top: 60px;} */

  .responsive-preview-container{
    width:  100% !important;
    height: 100% !important;
  }

  .max-w-landscape{  max-width: 150px;  }
  .max-w-portrait {  max-width: 150px;  }

  /*------For Campaign/Playlist page ------- */
  .width-content {  width: 75%  }
  .width-playlist{  width: 75%  }
  
  .height-landscape{  height: 150px}
  .height-portrait {  height: 230px}
}

@media only screen and (max-width: 768px) {
  .task-list-table{
    width: 225px;
  }
  .responsive-preview-container{
    width:  100% !important;
    height: 100% !important;
  }

  .max-w-landscape{  max-width: 150px;  }
  .max-w-portrait {  max-width: 150px;  }

  /*------For Campaign/Playlist page ------- */
  .width-content {  width: 75%  }
  .width-playlist{  width: 75%  }
  
  .height-landscape{  height: 150px}
  .height-portrait {  height: 200px}
}
@media only screen and (max-width: 480px) {
  .responsive-preview-container{
    width:  100% !important;
    height: 100% !important;
  }

  /*------For Campaign/Playlist page ------- */
  .width-content {  width: 100%  }
  .width-playlist{  width: 100%  }
  
  .height-landscape{  height: 130px}
  .height-portrait {  height: 200px}
}
@media only screen and (max-width: 320px) {

}
