//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

// Theme colors
// Primary
// $primary:       									#331168;
// $primary-active:    								#48256C;
// // $primary-light:    								    #F1EDF6;
// $primary-light:    								    #E9E1F6;
// $primary-light-dark:    							#2D0E4D;
// $primary-inverse:  									$white;

$primary:       									#9C258F;
$primary-active:    								#841076; //#69025C;
$primary-light:    								    #FBE5F9; //#F3CCEE;
// $primary-light:    								    #F3CCEE;
// $primary-light-dark:    							#69025C; //#32002C;
$primary-light-dark:    							#32002C;
$primary-inverse:  									$white;


// $primary:       									#6D5593;
// $primary-active:    								#543D7B;
// $primary-light:    								    #f8f5ff;
// $primary-light-dark:    							#2f264f;
// $primary-inverse:  									$white;


// Success
$success:       									#4FA34B;
$success-active:    								#2A8326;
$success-light:    									#e8fff3;
$success-light-dark:    							#0F620B;
$success-inverse:  									$white;

// $success:       									#50cd89;
// $success-active:    								#47be7d;
// $success-light:    									#e8fff3;
// $success-light-dark:    							#1c3238;
// $success-inverse:  									$white;

// Info
$info:       									    #009ef7;
$info-active:    									#0095e8;
// $info-light:    									#f1faff;
$info-light:    									#c3e2f7;
$info-light-dark:    								#212e48;
$info-inverse:  									$white;


// Danger
$danger:       									    #C80815;
$danger-active:    									#F85662;
$danger-light:    									#FFECEE;
$danger-light-dark:    								#560006;
$danger-inverse:  									$white;

// Danger
// $danger:       									    #A02E31;
// $danger-active:    									#C85C5E;
// $danger-light:    									#FFF6F6;
// $danger-light-dark:    								#780D10;
// $danger-inverse:  									$white;


// $danger:       									    #f1416c;
// $danger-active:    									#d9214e;
// $danger-light:    									#fff5f8;
// $danger-light-dark:    								#3a2434;
// $danger-inverse:  									$white;

// Warning
// $warning:       									#C9A45C;
// $warning-active:    								#A27A2F;
// $warning-light:    									#FFFCF6;
// $warning-light-dark:    							#79540D;
// $warning-inverse:  									$white;

$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;