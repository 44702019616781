#banner .slick-list { 
  margin: 0 0px; 
}
#home .slick-slide > div,
#home.container-fluid .slick-slide > div ,
#banner .slick-slide > div
{ 
  padding: 0px !important; 
  margin: 0px !important; 
}

#footer-bottom{
  background-image: url('../../../../public/media/_landing_banners/footer-silhouette-003.png');
  /* background-image: url('../../../../public/media/_landing_banners/footer-silhouette-002.png'); */
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: center;
  background-size: contain ;
}

.counter ol { 
  content: counters(item, ".") ". "; 
  counter-reset: item 
}
.counter li { 
  display: block;
  font-weight: bold;
}
.counter li:before { 
  content: counters(item, ".") ". "; 
  counter-increment: item 
}
ol p{
  font-size: 1rem;  
  font-weight: normal;
  color: #181C32;
}

@media screen and (max-width: 900px) {
  #footer-bottom{
    background-size: cover ;
  }
}